import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment-timezone';



const WorldClock = ({ timeZone }) => {
    const [currentTime, setCurrentTime] = useState(moment());
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentTime(moment());
      }, 1000); // Update time every second
  
      return () => clearInterval(interval); // Clean up the interval on unmount
    }, []);
  
    const formattedTime = currentTime.tz(timeZone).format('HH:mm');
  
    return (
      <div>
        {/* <h2>{timeZone}</h2> */}
        <p>——— {formattedTime}</p>
      </div>
    );
  };
  
  export default WorldClock;
  