import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom";
import Discc from "../../images/Managment-5.png";
import AOS from 'aos';
import 'aos/dist/aos.css'

const Discretionary = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, []);
  return (
    <>
      <section className="section-1 py-5 ">
        <div
          className="container-fluid Magg-11 
         " id="disc"
        >
          <div className="row my-auto" data-aos="fade-up">
            <div className="col-md-1 my-auto"></div>
            <div className="col-md-5 my-auto ">
              <img className="weal-11" src={Discc} alt="image"></img>
            </div>

            <div className="col-md-4 my-auto rangs ">
              <h2 className=" cut-11 ">04</h2>
              {/* <p className="cut-12">——— Services</p> */}
              <h2 className="cut-13">Discretionary Mandates & Advisory Services</h2>
              <p className="cut-14 text-justify">
                We offer innovative discretionary management solutions that are hybrid, at the frontier between human judgment and machine efficiency with state of the art algorithms. The goal is to have more capacity to withstand and adapt to regime changes and momentum reversals in markets. As independent wealth managers, we are not subject to any managerial quota nor shareholder return.
              </p>

              {/* <h4 className="cut-15">
                discover <br />
                more
              </h4> */}
              {/* <a href="#down">  <p className="cut-16">SCROLl DOWN ——— </p></a> */}

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Discretionary;
