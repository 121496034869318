import React, { useEffect } from "react";
import { Form, NavLink } from "react-router-dom";
import The from "./images/values-8.png";
import They from "./images/values-9.png";
import There from "./images/values-3.png";
import Theree from "./images/values-4.png";
import values6 from "./images//values-7.png";
import Thereeee from "./images/values-6.png";
import AOS from "aos";
import "aos/dist/aos.css";

const Values = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      <section className="section-1 " data-aos="fade-up">
        <p className="phillo text-center pt-3">Philosophy</p>
        <h1 className="Mission text-center ">Values</h1>

        <div className="container-fluid robo-1 " data-aos="fade-up">
          <div
            className="row robo flex-column-reverse flex-lg-row"
            data-aos="fade-up"
          >
            <div className=" col-md-6 my-auto">
              <h2 className="custom  ">The quest for knowledge</h2>

              <p className="phillo-1 pt-4 text-justify">
                We make sure our people understand the stakes of the business.
                To do so we share views, encourage a spirit of emancipation, and
                help our team in their quest for knowledge. We constantly
                innovate and build ways to invest but also to communicate with
                the people we deal with, whether clients or staff, to know more
                of the businesses they finance and support.
              </p>
            </div>
            <div className="col-md-6">
              <img className="mask img-fluid" src={The} alt="logo" />
            </div>
          </div>

          <div className="row robo " data-aos="fade-up">
            <div className="col-md-6 my-auto">
              <img className="mask img-fluid" src={They} alt="logo" />
            </div>
            <div className=" col-md-6 my-auto">
              <h2 className="custom  ">Transparency</h2>
              <p className="phillo-1 pt-4 text-justify">
                We are accountable, in other words we do what we say. In
                addition to being financially sound, investment proposals are
                made as if they were our own money. Trust is not due, it is
                earned. To do so, we are consistent in our selection process,
                have courage to bear potential odds and communicate on the way
                we work. Revealing and explaining our methods is a mindset, a
                culture. There is no such a thing as a “black box” at Omega.
              </p>
            </div>
          </div>

          <div
            className="row robo flex-column-reverse flex-lg-row"
            data-aos="fade-up"
          >
            <div className=" col-md-6 my-auto">
              <h2 className="custom  ">Integrity</h2>
              <p className="phillo-1 pt-4 text-justify">
                We do not choose investments according to the commissioning
                Omega will get. Immediate profit is a trap that jeopardizes long
                term relations. Integrity is wired into our governance; we make
                sure that all staff share common ethical principles. We are
                investors, not brokers.
                <br />
                <br />
                We thrive to offer the best to our clients, to you, not only by
                helping you get richer, but also getting to the point where your
                money works for the life you want to live. Beyond being
                financiers, we are project builders, true partners.
              </p>
            </div>
            <div className="col-md-6">
              <img className="mask-1 img-fluid" src={There} alt="logo" />
            </div>
          </div>

          <div className="row robo " data-aos="fade-up">
            <div className="col-md-6 my-auto">
              <img className="mask-3  img-fluid  " src={Theree} alt="logo" />
            </div>
            <div className=" col-md-6 my-auto">
              <h2 className="custom  ">Respecting experience</h2>
              <p className="phillo-1 pt-3 text-justify">
                “Nothing ever becomes real 'til it is experienced.” _― John
                Keats
                <br /> <br /> Omega’s staff refers to leaders who have been
                successful in their field. The cumulated experience of our team
                and partners is measured in centuries. In the past 30 years,
                Omegas’ seniors have endured amongst the most severe financial
                crisis of capitalism. There is no such a thing as “a good wealth
                manager”, there are only experienced ones.
              </p>
            </div>
          </div>
          <div
            className="row robo flex-column-reverse flex-lg-row"
            data-aos="fade-up"
          >
            <div className=" col-md-6 my-auto">
              <h2 className="custom  ">Long term vision only</h2>
              <p className="phillo-1 pt-3 text-justify">
                Wealth management is a long-haul journey for one reason: Profit
                is a human production and as such requires time to compound
                organically. Excessive short-term performance is doomed to
                failure due to its disproportionate reliance on marketing and
                flock dependency. We exclusively focus on the long term and seek
                to invest in products we deeply analyze, understand and track.
              </p>
            </div>
            <div className="col-md-6 my-auto">
              <img className="mask-3  img-fluid  " src={values6} alt="logo" />
            </div>
          </div>
          <div className="row robo" data-aos="fade-up">
            <div className="col-md-6 my-auto">
              <img className="mask-3 img-fluid   " src={Thereeee} alt="logo" />
            </div>
            <div className=" col-md-6 my-auto">
              <h2 className="custom  ">External inspirations</h2>
              <p className="phillo-1 pt-3 text-justify">
                One cannot succeed alone. This assertion is suitable for all
                 entrepreneurial ventures particularly in finance due to its
                complexity. Omega has developed a worldwide network spanning
                from Geneva to New York, San Francisco, Singapore, and Dubai.
                Our connections serve the purpose of making investment choices
                with the multiples benefits of diversity. Our external fund
                selection are managed by highly focused teams in sectors
                requiring a full technical and sector immersion that we would
                otherwise miss.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Values;
