import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Weall from "../../images/wealth-2.png";
import Wealll from "../../images/wealth-3.png";
import AOS from "aos";
import "aos/dist/aos.css";

const Ahead = () => {

  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      <section className="section-1 pt-5">
        <div
          className="container-fluid Magg-1  "
          id="scroll"
          data-aos="fade-up"
        >
          <div className="row my-auto">
            {/* <div className="col-md-1 my-auto"></div> */}
            <div className="offset-md-1 col-md-3 speed  my-auto ">
              <div>
                <img
                  className="weal-11 img-fluid "
                  src={Weall}
                  alt="image"
                ></img>
              </div>
            </div>
            <div className="col-md-4 my-auto rangs">
              <h2 className=" cut-11 ">02</h2>
              <p className="cut-12">——— Philosophy</p>
              <h2 className="cut-13">The Road Ahead</h2>
              <p className="cut-14 text-justify">
                As wealth managers we constantly challenge our opinions and
                views. Our quest for information is primarily a daily news
                track, but also sourced from leading think-tanks, consulting
                firms, university department leaders, forefront financiers and
                fund managers.
              </p>

              <h4 className="cut-15" style={{ cursor: 'pointer' }}>
                <a onClick={() => navigate('/road-ahead')}>discover <br /> more</a>
              </h4>

            </div>
            <div className="col-md-4 speed-1">
              <div>
                <img className="img-fluid " src={Wealll} alt="image"></img>
                <a href="#ahead">
                  {" "}
                  <p className="cut-16">SCROL DOWN ——— </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Ahead;
