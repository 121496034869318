import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom";
import Manage from "../../images/Managment-1.png";
import Managee from "../../images/Managment-6.png";
import AOS from 'aos';
import 'aos/dist/aos.css'


const Family = () => {
    useEffect(() => {
        AOS.init({ duration: 2000 })
    }, []);
    return (
        <>
            <section className="section-1 ">
                <div className="container-fluid Magg  ">
                    <div className="row py-5 flex-column-reverse flex-lg-row" data-aos="fade-up">
                        <div className="col-md-1 my-auto"></div>
                        <div className="col-md-5 my-auto ">
                            <h2 className=" cut-1 ">05</h2>
                            {/* <p className="cut-2">——— Services</p> */}
                            <h2 className="manage-3">Family Office Services, Financial Planning</h2>
                            <p className="cut-4 text-justify">
                                Wealth management does not only boil down to money, it also includes aspects such as family business continuity, estate equalization, legacy planning and lifestyle. Omega has historically kept a strong family approach, deliberately limiting its relations to cater for best-in-class service. Moreover, Omega is regulated by ARIF*, a Self-Regulated Organization (SRO) recognized by the Swiss Financial Market Supervisory Authority (FINMA) and the DFSA in the UAE, thus purveying a haven for its investors. It is no coincidence that families rely on us.
                            </p>

                            {/* <h4 className="cut-5">
                                discover <br />
                                more
                            </h4> */}

                        </div>
                        <div className="col-md-4 speed  my-auto ">
                            <div>
                                <img className="manage-1" src={Managee} alt="image"></img>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Family;
